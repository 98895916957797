import React from 'react'
import './style.scss'
import { HiOutlineDatabase, HiOutlineDeviceMobile, HiOutlineOfficeBuilding, HiOutlineTemplate, HiOutlineTerminal, HiOutlineUserGroup, HiOutlineViewGridAdd } from "react-icons/hi";
import Accordion from 'react-bootstrap/Accordion';
import img from "./../../assets/img2.svg"
import logo from "./../../assets/logo.png"

export default function Home() {
  return <main>
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <a className="navbar-brand" href="#">
          {/* cjdtech */}
          <img src={logo} alt="" width={140} />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-link active" aria-current="page" href="#">Início</a>
            <a className="nav-link" href="#solucoes">Soluções</a>
            <a className="nav-link" href="#servicos">Serviços</a>
            <a className="nav-link" href="#contato">Contato</a>
          </div>
        </div>
        {/* <form className="d-flex" role="search">
          <input className="form-control me-2" type="search" placeholder="Pesquisar" aria-label="Pesquisar" />
          <button className="btn btn-outline-primary" type="submit">Buscar</button>
        </form> */}
      </div>
    </nav>
    <section>
      <div className="container banner d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <h1 className='display-4'><span className='text-gradient'>Inovação</span>  que <span className='text-gradient'>impulsiona</span>  o futuro, desenvolve talentos e transforma <span className='text-gradient'>negócios</span>.</h1>
              <p className="lead">Somos especialistas em soluções tecnológicas que impulsionam o crescimento do seu negócio.</p>
              <button type="button" className="btn btn-dark me-2">Comece seu projeto</button>
              <button type="button" className="btn btn-dark">Agende uma consulta
              </button>
            </div>
            <div className="col-md-5 divdel">
              <img src={img} alt="" className='img-fluid' />
            </div>
          </div>
        </div>
      </div>

      <div className="beneficios" id='solucoes'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <h2 className="display-5">Benefícios que <span className='text-gradient'>impulsionam</span>   seu <span className='text-gradient'>negócio</span></h2>
              <p>Transformamos e modernizamos empresas, por fornecer e capacitar com o que há de mais moderno em tecnologia de informação.</p>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineTemplate className='icon' />
                      <h5 className="card-title">Soluções Inovadoras</h5>
                      <p className="card-text">Oferecemos soluções modernas e personalizadas para atender às suas necessidades específicas, utilizando as tecnologias mais avançadas do mercado.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineUserGroup className='icon' />
                      <h5 className="card-title">Nosso Diferencial</h5>
                      <p className="card-text">Contamos com uma equipe altamente qualificada e experiente, comprometida em oferecer um atendimento personalizado e de alta qualidade.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineViewGridAdd className='icon' />
                      <h5 className="card-title">Celeridade</h5>
                      <p className="card-text">Entregamos as soluções dentro dos prazos acordados, com agilidade e eficiência, para que você possa alcançar seus objetivos rapidamente.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <HiOutlineOfficeBuilding className='icon' />
                      <h5 className="card-title">Superação de Desafios</h5>
                      <p className="card-text">Ajudamos as empresas a superar os desafios da transformação digital, implementando soluções inovadoras e personalizadas que impulsionam o crescimento do negócio.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className="visiao">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4">
              <h2 className="display-5 ">Soluções únicas para o <span className='text-gradient'>sucesso</span>  do seu <span className='text-gradient'>negócio</span> </h2>
            </div>
            <div className="col-md-4">

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" className='accordion'>
                  <Accordion.Header className='border-0'><b>Nossa Missão</b></Accordion.Header>
                  <Accordion.Body>
                    Transformar e modernizar as empresas angolanas, por fornecer e capacitar no que há de mais moderno em tecnologia de informação.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='accordion'>
                  <Accordion.Header className='border-0'><b>Nossa Visão</b></Accordion.Header>
                  <Accordion.Body>
                    Ser uma referência nacional no sector da tecnologia corporativa, bem como um catalisador do ecossistema de TI angolano.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='accordion'>
                  <Accordion.Header className='border-0'><b>Nossos Valores</b></Accordion.Header>
                  <Accordion.Body>
                    Reconhecendo que a construção de uma identidade e cultura fortes é determinante para a concretização dos desafios a que se propõe, a cjdtech detém os seguintes valores:
                    Inovação, Assertividade, Espírito Pró-Activo, Integridade Intransigente
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {/* <h3 className="card-title">Nossa Missão</h3>
              <p className="card-text">Transformar e modernizar as empresas angolanas, fornecendo e capacitando-as com o que há de mais moderno em tecnologia da informação.</p>
              <h3 className="card-title">Nossa Visão</h3>
              <p className="card-text">Ser referência nacional no setor da tecnologia corporativa e catalisador do ecossistema de TI angolano.</p>
           \
              <h3 className="card-title">Nossos Valores</h3>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Inovação</li>
                <li className="list-group-item">Assertividade</li>
                <li className="list-group-item">Espírito Pró-Ativo</li>
                <li className="list-group-item">Integridade Intransigente</li>
              </ul> */}
            </div>

          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className='w-100 imgBg0 divdel' />
        <div className="imgBg1 w-100 d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <h1>Temos soluções que tornam o seu negocio mais dinâmico</h1>
                <a href="https://wa.me/+244942259047" className="btn btn-dark mt-3">Fala conosco pelo whatsApp</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="servico " id='servicos'>
        <div className="container">
          <h1 className=" mb-5 pb-3 text-center"><span className='text-gradient'>Soluções</span>  sob medida <br /> <span className='text-gradient'>para</span>  o <span className='text-gradient'>seu negócio</span> </h1>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                  <HiOutlineTerminal className='icon' />
                  <h5 className="card-title">Desenvolvimento de Software</h5>
                  <p className="card-text">Soluções sob medida utilizando as tecnologias mais avançadas do mercado, atendendo às suas necessidades específicas.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                  <HiOutlineDeviceMobile className='icon' />
                  <h5 className="card-title">Desenvolvimento de Aplicativos</h5>
                  <p className="card-text">Criação de aplicativos Android e iOS intuitivos e personalizados, impulsionando a experiência dos seus usuários.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                  <HiOutlineDatabase className='icon' />
                  <h5 className="card-title">Hospedagem de Sites</h5>
                  <p className="card-text">Planos confiáveis e escaláveis de hospedagem, garantindo alta disponibilidade, segurança e rapidez para o seu site.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="planos" id='planos'>
  <div className="container">
    <h1 className="">Encontre o <span className='text-gradient'>plano perfeito</span> para o seu <br /> <span className='text-gradient'>negócio</span></h1>
    <p className="">Inicie com total confiança. Nossa garantia de reembolso de 30 dias significa que você não tem nada a perder.</p>
    <div className="row mt-5">
      <div className="col-md-4 mb-3">
        <div className="card border">
          <div className="card-body">
            <h3 className='mb-3'>Premium</h3>
            O plano ideal para sites pessoais
            <div className='d-flex align-items-end mb-3 mt-3'>
              <span className='text-gradient'><h1 className='m-0'>20.000 kz</h1> <h3 className='m-0'>/mês</h3></span>
            </div>
            <p>*Ao assinar por 48 meses; IVA não incluído</p>
            <p>+3 meses GRÁTIS</p>
            <hr />
            <p>100 GB Espaço SSD</p>
            <p>Criador de Sites Hostinger</p>
            <p>Domínio Grátis (9,99 €)</p>
            <p>Migração de Site Gratuita e Automática</p>
            <p>Email Gratuito</p>
            <p>SSL Grátis Ilimitado</p>
            <p>Backups Semanais</p>
            <p>CDN grátis</p>
            <p>WooCommerce</p>
            <p>Endereço de IP Dedicado</p>
            <p>Suporte Prioritário</p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-3">
        <div className="card border">
          <div className="card-body">
            <h3 className='mb-3'>Business</h3>
            O plano ideal para sites empresariais
            <div className='d-flex align-items-end mb-3 mt-3'>
              <span className='text-gradient'><h1 className='m-0'>20.000 kz</h1> <h3 className='m-0'>/mês</h3></span>
            </div>
            <p>*Ao assinar por 48 meses; IVA não incluído</p>
            <p>+3 meses GRÁTIS</p>
            <hr />
            <p>100 GB Espaço SSD</p>
            <p>Criador de Sites Hostinger</p>
            <p>Domínio Grátis (9,99 €)</p>
            <p>Migração de Site Gratuita e Automática</p>
            <p>Email Gratuito</p>
            <p>SSL Grátis Ilimitado</p>
            <p>Backups Semanais</p>
            <p>CDN grátis</p>
            <p>WooCommerce</p>
            <p>Endereço de IP Dedicado</p>
            <p>Suporte Prioritário</p>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-3">
        <div className="card border">
          <div className="card-body">
            <h3 className='mb-3'>Cloud Startup</h3>
            O plano perfeito para startups
            <div className='d-flex align-items-end mb-3 mt-3'>
              <span className='text-gradient'><h1 className='m-0'>20.000 kz</h1> <h3 className='m-0'>/mês</h3></span>
            </div>
            <p>*Ao assinar por 48 meses; IVA não incluído</p>
            <p>+3 meses GRÁTIS</p>
            <hr />
            <p>100 GB Espaço SSD</p>
            <p>Criador de Sites Hostinger</p>
            <p>Domínio Grátis (9,99 €)</p>
            <p>Migração de Site Gratuita e Automática</p>
            <p>Email Gratuito</p>
            <p>SSL Grátis Ilimitado</p>
            <p>Backups Semanais</p>
            <p>CDN grátis</p>
            <p>WooCommerce</p>
            <p>Endereço de IP Dedicado</p>
            <p>Suporte Prioritário</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      <div className="imgBg2"></div>
      <div className='chats'>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 mb-3">
              <p className='opacity-50'>CONSULTORIA GRATUÍTA</p>
              <h1 className='display-5'>
                Tem um projecto?
                Fale connosco.
              </h1>
              Agendar uma reunião virtual
              Preencha o formulário, que um de nossos especialistas entrará em contacto consigo dentro de 24h.
            </div>
            <div className="col-md-4 mb-3 d-flex align-items-center">
              <div>
                <a href="https://wa.me/+244942259047" className='btn'>Fala conosco pelo whatsApp</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer className="footer pt-5" id='contato'>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img src={logo} alt="" width={160} className='mb-3' />
            {/* <h3 className="footer-title">cjdtech</h3> */}
            <p className="footer-text">Somos uma empresa angolana com o objetivo de transformar e modernizar empresas, fornecendo e capacitando com o que há de mais moderno em tecnologia da informação.</p>
          </div>
          <div className="col-md-4">
            <h5 className="footer-title">Nossas Soluções</h5>
            <ul className="list-unstyled">
              <li><a href="#">Desenvolvimento de Aplicações</a></li>
              <li><a href="#">Infraestrutura Empresarial</a></li>
              <li><a href="#">Infraestrutura Cloud</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className="footer-title">Contacte-nos</h5>
            <p className="footer-text">Interessado em nossos serviços?</p>
            <ul className="list-unstyled">
              <li><a href="mailto:geral@cjdtech.ao">geral@cjdtech.ao</a></li>
              <li className='mt-2'><a href="tel:+244942259047">(+244) 942 259 047</a> <br /><a href="tel:+244990463965">(+244) 990 463 965</a> <br /><a href="tel:+244944463965">(+244) 944 463 965</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pt-5 pb-2 container opacity-50">
        <span className="footer-copyright">&copy; 2024 cjdtech. Todos os direitos reservados.</span>
      </div>
    </footer>
  </main>
}
